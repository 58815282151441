import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_LOGIN_URL;

export const reportDetailApi = createApi({
  reducerPath: 'reportDetailApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getUserReportData: builder.query({
      query: (params) => ({
        url: `${url}/users/report-detail?email=${params.email}`,
      }),
    }),
  }),
});
export const { useGetUserReportDataQuery } = reportDetailApi;
