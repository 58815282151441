import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_RENT_COLLECTION;

export const rentDeductionApi = createApi({
  reducerPath: 'rentDeductionApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    // configuration
    getRentDeductionConfigurations: builder.query({
      query: () => ({
        url: `${url}/configuration`,
      }),
      providesTags: ['RentDeductionConfigurations'],
    }),
    // deleteRentDeductionConfigurations: builder.mutation({
    //   query: (params) => ({
    //     url: `${url}/configuration`,
    //     method: 'DELETE',
    //     params: params,
    //   }),
    //   invalidatesTags: ['RentDeductionConfigurations'],
    // }),
    postRentDeductionConfigurations: builder.mutation({
      query: (params) => ({
        url: `${url}/configuration`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['RentDeductionConfigurations'],
    }),
    putRentDeductionConfigurations: builder.mutation({
      query: (params) => ({
        url: `${url}/configuration`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['RentDeductionConfigurations'],
    }),

    // recharge history
    postRentDeductionRechargeHistory: builder.mutation({
      query: (data) => ({
        url: `${url}/ledger-history`,
        method: 'POST',
        body: data,
      }),
    }),
    //ledgers
    getConfiguredSites: builder.query({
      query: () => ({
        url: `${url}/configuration/sites`,
      }),
      providesTags: ['RentDeductionConfigurations'],
    }),
    //ledgers
    getRentDeductionLedgers: builder.query({
      query: (params) => ({
        url: `${url}/ledgers`,
        params: params,
      }),
      providesTags: ['RentDeductionConfigurations'],
    }),
  }),
});
export const {
  useGetRentDeductionLedgersQuery,
  // useDeleteRentDeductionConfigurationsMutation,
  useGetRentDeductionConfigurationsQuery,
  usePutRentDeductionConfigurationsMutation,
  usePostRentDeductionConfigurationsMutation,
  usePostRentDeductionRechargeHistoryMutation,
  useGetConfiguredSitesQuery,
} = rentDeductionApi;
