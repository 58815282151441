import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: '',
};

const calenderSlice = createSlice({
  name: 'calender',
  initialState,
  reducers: {
    setCalender: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCalender } = calenderSlice.actions;

export default calenderSlice.reducer;
