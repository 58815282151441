// ** React Imports
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, batch } from 'react-redux';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { toast } from 'react-toastify';
import * as Icon from 'react-feather';
import classnames from 'classnames';

// ** Redux Slice Import
import { updateMDMSHierarchyProgress } from '../../../app/redux/mdmsHeirarchySlice';
import { useLazyGISMeterSearchQuery } from '../../../api/hes/command-historySlice';

const NavbarSearch = ({ setOpenSearchBar }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const project =
    location.pathname.split('/')[2] === 'sbpdcl'
      ? 'ipcl'
      : location.pathname.split('/')[2];

  const [userInputParameter, setUserInputParameter] = useState('');
  const [selectedOption, setSelectedOption] = useState('meter');
  const [search, response] = useLazyGISMeterSearchQuery();

  const searchMeter = () => {
    const params = {
      project: 'ipcl',
      unique_id: userInputParameter,
      asset_type: selectedOption,
    };
    // Trigger the search
    search(params);
  };

  useEffect(() => {
    if (response.status === 'fulfilled') {
      const statusCode = response.currentData.responseCode;
      if (statusCode === 200) {
        const meter_details = response.currentData.data.result.stat.asset;
        if (meter_details.length > 0) {
          toast('Asset Found ....', { hideProgressBar: true, type: 'success' });

          batch(() => {
            const meter = meter_details[0];
            const payload = {
              project_type: location.pathname.split('/')[1],
              project_name: project,
              module_name: 'mdms',
              pss_name: meter.pss_id,
              feeder_name: meter.feeder_id,
              dtr_name: selectedOption === 'dtr' ? meter.site_id : '',
              user_name: selectedOption === 'meter' ? meter.sc_no : '',
              meter_serial_number:
                selectedOption === 'meter' ? meter.meter_number : '',
              mdms_state:
                selectedOption === 'meter' ? 'user_profile' : selectedOption,
              user_type:
                selectedOption === 'meter' ? meter.connection_type : '',
              dtr_real_name: selectedOption === 'dtr' ? meter.site_name : '',
              feeder_real_name: meter.feeder_name,
              grid_id: meter.grid_id,
              meter_address: meter.meter_address,
              meter_protocol_type: meter.meter_protocol_type?.toLowerCase(),
              pss_real_name: meter.pss_name,
              user_real_name: '',
            };
            dispatch(updateMDMSHierarchyProgress(payload));
          });
          navigate(
            `/realestate/gp-admin/mdms/${meter_details?.[0]?.site_id}/${meter_details?.[0]?.sc_no}`
          );
          setOpenSearchBar(false);
        } else {
          toast('No result found ....', {
            hideProgressBar: true,
            type: 'error',
          });
        }
      }
    } else if (response.isError) {
      toast('Something went wrong, please retry.', {
        hideProgressBar: true,
        type: 'error',
      });
    }
  }, [response, dispatch, location.pathname, project, selectedOption]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      if (userInputParameter) {
        searchMeter();
      }
    }
  };

  const handleSearchClick = () => {
    if (userInputParameter) {
      searchMeter();
      // if (response.status === 'fulfilled') {
      //   navigate(
      //     `/realestate/gp-admin/mdms/${meterDetail?.[0]?.site_id}/${meterDetail?.[0]?.sc_no}`
      //   );
      //   setOpenSearchBar(false);
      // }
    }
  };

  return (
    <div className="d-flex justify-content-end align-items-center w-100">
      {/* <InputGroup className="justify-content-end align-items-center me-2"> */}
      <Input
        type="text"
        placeholder="Search for Meter ip, meter sc_no , site_id"
        className="search-input-mdms me-2"
        value={userInputParameter}
        onChange={(e) => setUserInputParameter(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Button
        className="me-2 bg-primary"
        style={{ cursor: 'pointer' }}
        onClick={handleSearchClick}
      >
        Search
      </Button>
      {/* <InputGroupText className="bg-transparent">
          <Icon.Search
            style={{ cursor: 'pointer' }}
            onClick={handleSearchClick}
          />
        </InputGroupText>
      </InputGroup> */}
      <Icon.X
        className="me-1"
        onClick={(e) => {
          e.stopPropagation();
          setOpenSearchBar(false);
        }}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

export default NavbarSearch;
