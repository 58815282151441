import React from 'react';

// Coliving Lazy Components
export const LazyColivingComponents = {
  BulkCommand: React.lazy(() => import('../utility/module/coliving')),
  HealthPortal: React.lazy(() => import('../utility/module/coliving')),
  Billanalysis: React.lazy(() => import('../utility/module/coliving')),
  RentCollection: React.lazy(() => import('../utility/module/coliving')),
  Configuration: React.lazy(() => import('../utility/module/coliving')),
  Analytics: React.lazy(() => import('../utility/module/coliving')),
  Dashboard: React.lazy(() => import('../utility/module/coliving')),
  Mdms: React.lazy(() => import('../utility/module/realEstate/module/mdms')),
  Survey: React.lazy(() => import('../utility/module/coliving/module/survey')),
};

// RealEstate Lazy Components
export const LazyRealEstateComponents = {
  BulkCommand: React.lazy(() => import('../utility/module/realEstate')),
  HealthPortal: React.lazy(() => import('../utility/module/realEstate')),
  Configuration: React.lazy(() => import('../utility/module/realEstate')),
  Analytics: React.lazy(() => import('../utility/module/realEstate')),
  Dashboard: React.lazy(() => import('../utility/module/realEstate')),
  Mdms: React.lazy(() => import('../utility/module/realEstate/module/mdms')),
  Gis: React.lazy(() => import('../utility/module/realEstate/module/gis')),
  Hes: React.lazy(() => import('../utility/module/realEstate/module/hes')),
  Survey: React.lazy(() => import('../utility/module/coliving/module/survey')),
};

// Utility Lazy Components
export const LazyUtilityComponents = {
  Hes: React.lazy(() => import('../utility/module/hes')),
  Mdms: React.lazy(() => import('../utility/module/mdms')),
  SlaReports: React.lazy(() => import('../utility/module/sla-Reports')),
  Sat: React.lazy(() => import('../utility/module/sat')),
};
