import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

// const url = process.env.REACT_APP_COLIVING_ISSUES;
const url = 'http://192.168.101.7:8105/ims';

export const issueManagementApi = createApi({
  reducerPath: 'issueManagementApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getColivingTableIssue: builder.query({
      query: (params) => ({
        url: `${url}/issues`,
        params: params,
      }),
      providesTags: ['ColivingTableIssue'],
    }),
    postColivingIssue: builder.mutation({
      query: (params) => ({
        url: `${url}/issues`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['ColivingTableIssue'],
    }),
    deleteIssue: builder.mutation({
      query: (row) => ({
        url: `${url}/issues?id=${row.id}&deletedBy=${row.email}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ColivingTableIssue'],
    }),
    putUpdateIssue: builder.mutation({
      query: (data) => ({
        url: `${url}/issues?id=${data[0].id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ColivingTableIssue'],
    }),
    updateIssueStatus: builder.mutation({
      query: (data) => ({
        url: `${url}/issues?id=${data[0].id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ColivingTableIssue'],
    }),
    updateBulkIssueStatus: builder.mutation({
      query: (data) => ({
        url: `${url}/issues?id=${data[0].id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ColivingTableIssue'],
    }),
  }),
});
export const {
  usePostColivingIssueMutation,
  useGetColivingTableIssueQuery,
  useDeleteIssueMutation,
  usePutUpdateIssueMutation,
  useUpdateIssueStatusMutation,
  useUpdateBulkIssueStatusMutation,
} = issueManagementApi;
