import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCollapsed } from '../../../app/redux/layoutSlice';
import '../../../styles/layout.scss';
import { Award, Circle } from 'react-feather';
import { jwtDecode } from 'jwt-decode';

const parseAccess = (accessArray) => {
  return accessArray.map((item) => ({
    id: item.id,
    icon: item.icon || 'Circle',
    title: item.title,
    navLink: item.navLink || null,
    children: item.children ? parseAccess(item.children) : [],
  }));
};

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const isMobileSidebarOpen = useSelector(
    (state) => state.layout.isMobileSidebarOpen
  );
  const [userAccess, setUserAccess] = useState([]);
  const [openVertical, setOpenVertical] = useState(
    location.pathname.split('/')[1]
  );
  const [openProject, setOpenProject] = useState(
    location.pathname.split('/')[2]
  );

  const toggleCollapsed = () => {
    dispatch(setCollapsed(!collapsed));
  };

  const handleSubMenuChange = (id, level) => {
    if (level === 'vertical') {
      setOpenVertical(openVertical === id ? null : id); // Toggle vertical
      setOpenProject(null); // Close project when vertical changes
    } else if (level === 'project') {
      setOpenProject(openProject === id ? null : id); // Toggle project
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded && decoded.userData && decoded.userData.access) {
        const parsedAccess = parseAccess(decoded.userData.access);
        setUserAccess(parsedAccess);
      }
    }
  }, []);
  const renderMenuItems = (access, level = 'vertical') => {
    return access.map((item) =>
      item.children && item.children?.length > 0 ? (
        <SubMenu
          key={item.id}
          icon={<Award size={18} />}
          label={item.title}
          open={
            level === 'vertical'
              ? item.id.toLowerCase() === openVertical
              : item.id.toLowerCase() === openProject
          } // Control open state
          onOpenChange={(open) =>
            open
              ? handleSubMenuChange(item.id.toLowerCase(), level)
              : handleSubMenuChange(null, level)
          }
        >
          {renderMenuItems(item.children, 'project')}
        </SubMenu>
      ) : (
        <MenuItem
          style={{
            marginBottom: '3px',
            marginLeft: '4px',
            marginRight: '4px',
          }}
          key={item.id}
          icon={<Circle size={12} />}
          onClick={() => navigate(item.navLink)}
          active={location.pathname === item.navLink}
          data-bs-dismiss={isMobileSidebarOpen ? 'offcanvas' : ''}
        >
          {item?.title?.length > 15 ? (
            <div class="marquee-container">
              <div class="marquee-text">{item.title}</div>
            </div>
          ) : (
            item.title
          )}
        </MenuItem>
      )
    );
  };

  return (
    <div className="pt-1 white">
      <Sidebar collapsed={collapsed} width="280px">
        <Menu
          menuItemStyles={{
            button: ({ level, active }) => {
              return {
                color: active ? 'white' : undefined,
                backgroundColor: active ? '#7367f0' : undefined,
                borderRadius: active ? '20px' : undefined,
                boxShadow: active
                  ? '0px 5px 10px rgba(0, 0, 0, 0.35)'
                  : undefined,
                '&:hover': {
                  backgroundColor: '#7367f0',
                  color: 'white',
                  borderRadius: '20px',
                  // animation: 'slideInInfinite 1s linear infinite',
                  // boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.35)',s
                },
              };
            },
          }}
        >
          {renderMenuItems(userAccess)}
        </Menu>
      </Sidebar>
      {/* <div className="d-none d-sm-flex" onClick={toggleCollapsed}>
        {collapsed ? (
          <ChevronRight
            className="cursor-pointer chevron-icon chevron-right"
            size={30}
          />
        ) : (
          <ChevronLeft
            className="cursor-pointer chevron-icon chevron-left"
            size={30}
          />
        )}
      </div> */}
    </div>
  );
}

export default SideBar;
