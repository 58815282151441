import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

// const url = process.env.REACT_APP_LOGIN_URL;
const url = 'http://192.168.100.125:8011';
// const url =
// 'https://35d2-2409-40d4-4041-eb2b-3b84-33a9-4d5e-15b2.ngrok-free.app';

export const usersAccessPanelApi = createApi({
  reducerPath: 'usersAccessPanelApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getUsersAccessList: builder.query({
      query: (params) => ({
        url: `${url}/get-users`,
        params: params,
      }),
      providesTags: ['usersAccessList'],
    }),
    getUserStatus: builder.query({
      query: (params) => ({
        url: `${url}/user-status`,
        params: params,
      }),
      invalidatesTags: ['usersAccessList'],
    }),
    accessDetailList: builder.query({
      query: () => ({
        url: `${url}/access-detail-list`,
      }),
    }),
    getSiteList: builder.query({
      query: (params) => ({
        url: `${url}/site-list`,
        params: params,
      }),
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: `${url}/signup`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['usersAccessList'],
    }),
    //module management
    getModule: builder.query({
      query: (params) => ({
        url: `${url}/module`,
        params: params,
      }),
      providesTags: ['module'],
    }),
    postModule: builder.mutation({
      query: (data) => ({
        url: `${url}/module`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['module'],
    }),
    putModule: builder.mutation({
      query: (data) => ({
        url: `${url}/module`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['module'],
    }),
  }),
});
export const {
  useGetUsersAccessListQuery,
  useLazyGetUserStatusQuery,
  useAccessDetailListQuery,
  useGetSiteListQuery,
  //module management
  useGetModuleQuery,
  usePostModuleMutation,
  usePutModuleMutation,
} = usersAccessPanelApi;
