import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_CONSUMER_CONFIG;

export const configurationApi = createApi({
  reducerPath: 'configurationApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    // EB/DG Allocation
    getEbDgSites: builder.query({
      query: () => ({
        url: `${url}/eb-dg-allocation/get-sites`,
      }),
      providesTags: ['EbDgSites'],
    }),
    postEbDgConfig: builder.mutation({
      query: (params) => ({
        url: `${url}/eb-dg-allocation`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['EbDgSites'],
    }),
    putEbDgConfig: builder.mutation({
      query: (params) => ({
        url: `${url}/eb-dg-allocation`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['EbDgSites'],
    }),
    getMeterOfSite: builder.query({
      query: (params) => ({
        url: `${url}/eb-dg-allocation/get-meter-of-site?site=${params.site}`,
      }),
    }),
    getEbDgMetersOfSite: builder.query({
      query: (params) => ({
        url: `${url}/eb-dg-allocation/get-eb-dg-meters-of-site?site=${params.site}`,
      }),
    }),
    // tariff
    getAllTatiffMeter: builder.query({
      query: (params) => ({
        url: `${url}/config-tariff/get-meters?site=${params.site}`,
      }),
    }),
    getEbDgMeter: builder.query({
      query: (params) => ({
        url: `${url}/config-tariff/get-eb-dg-meters?site=${params.site}`,
      }),
    }),
    postEbDgMeter: builder.mutation({
      query: (params) => ({
        url: `${url}/config-tariff`,
        method: 'POST',
        body: params,
      }),
    }),
    //tower configuration
    getConfiguredTowers: builder.query({
      query: () => ({
        url: `${url}/tower-config/get-tower-configured-list`,
      }),
      providesTags: ['ConfiguredTowers'],
    }),
    postTowerConfig: builder.mutation({
      query: (params) => ({
        url: `${url}/tower-config`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['ConfiguredTowers'],
    }),
    putTowerConfig: builder.mutation({
      query: (params) => ({
        url: `${url}/tower-config`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['ConfiguredTowers'],
    }),
    //Associate/Dissociate
    getProjectsAllConsumers: builder.query({
      query: (params) => ({
        url: `${url}/config-consumers/get-consumers?site=${params.site}`,
      }),
      providesTags: ['ProjectsAllConsumers'],
    }),
    syncAllMeters: builder.query({
      query: (params) => ({
        url: `${url}/fetch-meters?site_id=${params.site_id}`,
      }),
    }),
    getCredit: builder.query({
      query: (params) => ({
        url: `${url}/config-consumers/credits?sc_no=${params.sc_no}`,
      }),
    }),
    associateMeter: builder.mutation({
      query: (params) => ({
        url: `${url}/config-consumers/associate`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['ProjectsAllConsumers'],
    }),
    updateAssociated: builder.mutation({
      query: (params) => ({
        url: `${url}/config-consumers/associate`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['ProjectsAllConsumers'],
    }),
    dissociateMeter: builder.query({
      query: (params) => ({
        url: `${url}/config-consumers/dissociate?mobile=${params.mobile}&sc_no=${params.sc_no}&credit_remain=${params.credit_remain}&site_id=${params.site_id}`,
      }),
      invalidatesTags: ['ProjectsAllConsumers'],
    }),
    //Daily Deduction
    getAllDeduction: builder.query({
      query: (params) => ({
        url: `${url}/config-deduction?site=${params.site}`,
      }),
      providesTags: ['AllDeduction'],
    }),
    deleteDeduction: builder.mutation({
      query: (params) => ({
        url: `${url}/config-deduction/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllDeduction'],
    }),
    addUpdateDeduction: builder.mutation({
      query: (params) => ({
        url: `${url}/config-deduction`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['AllDeduction'],
    }),
    //Variable CAM
    getConfigUnconfigCamMeters: builder.query({
      query: (params) => ({
        url: `${url}/variable-cam-config/get-config-unconfig-cam-meters-list`,
        params: params,
      }),
      providesTags: ['ConfigUnconfigCamMeters'],
    }),
    getCamEnabledSites: builder.query({
      query: (params) => ({
        url: `${url}/variable-cam-config/get-cam-sites`,
        params: params,
      }),
    }),
    addUpdateVariableCam: builder.mutation({
      query: (params) => ({
        url: `${url}/variable-cam-config/add-update-variable-cam`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['ConfigUnconfigCamMeters'],
    }),
    //Prepaid / Postpaid
    getPostpaidList: builder.query({
      query: (params) => ({
        url: `${url}/postpaid-config/get-postpaid-list`,
        params: params,
      }),
      providesTags: ['PostpaidList'],
    }),
    putPostpaidToPrepaid: builder.mutation({
      query: (data) => ({
        url: `${url}/postpaid-config/postpaid-to-prepaid`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['PostpaidList'],
    }),
    postPostpaidList: builder.mutation({
      query: (data) => ({
        url: `${url}/postpaid-config/create-postpaid-consumer`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['PostpaidList'],
      // transformResponse: (response) => {
      //   console.log(response, 'response in rtk');
      //   return response;
      // },
    }),
    // site status
    getSiteStatus: builder.query({
      query: () => ({
        url: `${url}/site-record`,
      }),
      providesTags: ['SiteStatus'],
    }),
    getSiteStatusList: builder.query({
      query: () => ({
        url: `${url}/site-record/sites-list`,
      }),
      providesTags: ['SiteStatus'],
    }),
    postSiteStatus: builder.mutation({
      query: (data) => ({
        url: `${url}/site-record`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['SiteStatus'],
    }),
    putSiteStatus: builder.mutation({
      query: (data) => ({
        url: `${url}/site-record`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['SiteStatus'],
    }),
  }),
});
export const {
  useGetEbDgSitesQuery,
  usePostEbDgConfigMutation,
  usePutEbDgConfigMutation,
  useGetMeterOfSiteQuery,
  useGetEbDgMetersOfSiteQuery,
  useGetAllTatiffMeterQuery,
  useLazyGetEbDgMeterQuery,
  usePostEbDgMeterMutation,
  useLazyGetConfiguredTowersQuery,
  usePostTowerConfigMutation,
  usePutTowerConfigMutation,
  useGetProjectsAllConsumersQuery,
  useLazySyncAllMetersQuery,
  useGetCreditQuery,
  useAssociateMeterMutation,
  useUpdateAssociatedMutation,
  useLazyDissociateMeterQuery,
  useGetAllDeductionQuery,
  useDeleteDeductionMutation,
  useAddUpdateDeductionMutation,
  useGetConfigUnconfigCamMetersQuery,
  useGetCamEnabledSitesQuery,
  useAddUpdateVariableCamMutation,
  useGetPostpaidListQuery,
  usePutPostpaidToPrepaidMutation,
  usePostPostpaidListMutation,
  useGetSiteStatusQuery,
  useGetSiteStatusListQuery,
  usePostSiteStatusMutation,
  usePutSiteStatusMutation,
} = configurationApi;
