import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_MDAS_URL;

export const meterConfigurationColivingApi = createApi({
  reducerPath: 'meterConfigurationColivingApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    PostSurveyUploadFile: builder.mutation({
      query: (params) => ({
        url: `${url}/api/hes/mdm/meter-survey-conf/`,
        method: 'POST',
        body: params,
      }),
    }),
    postUploadFile: builder.mutation({
      query: (params) => ({
        url: `${url}/api/hes/mdm/meter-configurations`,
        method: 'POST',
        body: params,
      }),
    }),
    postUploadFileManually: builder.mutation({
      query: (params) => ({
        url: `${url}/api/hes/mdm/meter-configurations-manually/`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});
export const {
  usePostSurveyUploadFileMutation,
  usePostUploadFileMutation,
  usePostUploadFileManuallyMutation,
} = meterConfigurationColivingApi;
