import React, { useEffect, useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Media,
} from 'reactstrap';
import { Bell, Check, Circle, X } from 'react-feather';
import { Menu, MenuItem } from 'react-pro-sidebar';
import { toast } from 'react-toastify';

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const maxRetries = 5;
  let retryCount = 0;

  useEffect(() => {
    let eventSource;

    const connect = () => {
      eventSource = new EventSource(
        'https://api.staging.private.polarisgrids.com/scripts/sub-webhook/pvt_notification'
      );

      eventSource.onmessage = (event) => {
        const notificationentry = JSON.parse(event.data);

        setNotifications((prevNotifications) => [
          ...prevNotifications,
          notificationentry,
        ]);
        setUnreadCount((prevCount) => prevCount + 1); // Increment unread count
      };

      eventSource.onerror = (event) => {
        eventSource.close(); // Close the connection on error

        if (retryCount < maxRetries) {
          retryCount += 1;
          // toast('EventSource connection failed', {
          //   hideProgressBar: true,
          //   type: 'warning',
          // });
          connect(); // Retry immediately without delay
        } else {
          // toast('Failed to reconnect after multiple attempts', {
          //   hideProgressBar: true,
          //   type: 'error',
          // });
        }
      };
    };

    connect(); // Initial connection attempt

    return () => {
      if (eventSource) {
        eventSource.close(); // Ensure the connection is closed when the component unmounts
      }
    };
  }, []);

  const handleRead = (notificationIndex) => {
    const updatedNotifications = notifications.map((notification, index) =>
      index === notificationIndex
        ? { ...notification, checked: true }
        : notification
    );
    setUnreadCount(unreadCount - 1);
    setNotifications(updatedNotifications);
  };

  const handleRemove = (notificationIndex) => {
    const updatedNotifications = notifications.filter(
      (_, index) => index !== notificationIndex
    );
    setNotifications(updatedNotifications);
  };

  const renderNotificationItems = () => {
    return notifications.map((notification, index) => (
      <MenuItem
        key={index}
        icon={<Circle size={14} />}
        className="border border-muted"
      >
        <div className="d-flex align-items-center justify-content-between">
          <Media className="my-auto pt-1" body>
            <h6 className="font-weight-bolder mb-0">{notification.title}</h6>
            <p>{notification.message}</p>
          </Media>
          {notification?.checked === true ? (
            <X onClick={() => handleRemove(index)} className="light-warning" />
          ) : (
            <Check
              onClick={() => handleRead(index)}
              className="light-primary"
            />
          )}
        </div>
      </MenuItem>
    ));
  };

  return (
    <UncontrolledDropdown tag="li" className="nav-item mr-25">
      <DropdownToggle
        tag="a"
        className="nav-link"
        href="/"
        onClick={(e) => e.preventDefault()}
      >
        <Bell size={21} />
        {unreadCount > 0 && (
          <Badge pill color="danger" className="badge-up">
            {unreadCount}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu tag="ul" end className="mt-0 p-0">
        <div className="dropdown-menu-header">
          <DropdownItem
            className="d-flex justify-content-between align-items-center"
            tag="div"
            header
          >
            <h4 className="mb-0 me-4">Real-time Notifications</h4>
            <Badge tag="div" color="light-primary" pill>
              {unreadCount} New
            </Badge>
          </DropdownItem>
        </div>
        <div className="notification-sidebar">
          <Menu>{renderNotificationItems()}</Menu>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Notification;
