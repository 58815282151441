import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dtr: [],
};

const routingSlice = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    setRoutes: (state, action) => {
      state.dtr = action.payload;
    },
  },
});

export const { setRoutes } = routingSlice.actions;

export default routingSlice.reducer;
