import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_ANALYTICS;

export const sourceStatusApi = createApi({
  reducerPath: 'sourceStatusApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getSourceStatusSites: builder.mutation({
      query: (params) => ({
        url: `${url}/sites`,
        method: 'POST',
        body: params,
      }),
    }),
    getSourceStatusMeters: builder.query({
      query: (params) => ({
        url: `${url}/meters`,
        params: params,
      }),
    }),
  }),
});
export const {
  useGetSourceStatusSitesMutation,
  useGetSourceStatusMetersQuery,
} = sourceStatusApi;
