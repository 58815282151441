import { Row, Col } from 'reactstrap';
import OperationalInformationWrapper from './OperationalInformationUser';
import { ChevronLeft } from 'react-feather';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../../../../components/loader/loader';
import CardInfo from '../../../../../../../components/ui-elements/cards/cardInfo';
import DataTableV1 from '../../../../../../../components/dtTable/DataTableV1';
import { Badge } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { updateMDMSHierarchyProgress } from '../../../../../../../app/redux/mdmsHeirarchySlice';
import { useGetConsumerInfoListQuery } from '../../../../../../../api/coliving/mdmsSlice';
import { toast } from 'react-toastify';
import { Tooltip } from 'reactstrap';
import Cookies from 'js-cookie';

const AllUsers = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [response, setResponse] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [successTooltip, setSuccessTooltip] = useState(false);
  const [processingTooltip, setProcessingTooltip] = useState(false);
  const [rejectedTooltip, setRejectedTooltip] = useState(false);
  const siteName = Cookies.get('site_name');

  const hierarchy = useSelector((state) => state.MDMSHierarchyProgress.data);
  // site id from url
  const { site } = useParams();

  const onBackButtonClickHandler = () => {
    navigate(`/realestate/gp-admin/mdms`);
  };
  const setRowCount = (rowCount) => {
    setPageSize(rowCount);
    refetch();
  };

  const selectedYearMonth = useSelector((state) => state.calender.data);

  const [SelectedYear, setSelectedYear] = useState('');
  const [SelectedMonth, setSelectedMonth] = useState('');

  useEffect(() => {
    // Ensure it's a valid string before splitting
    if (
      typeof selectedYearMonth === 'string' &&
      selectedYearMonth.includes('-')
    ) {
      const [year, month] = selectedYearMonth.split('-');
      setSelectedYear(year);
      setSelectedMonth(month);
    } else {
      // Handle cases where selectedYearMonth is not in the expected format
      console.log('Invalid date format:', selectedYearMonth);
      setSelectedYear('');
      setSelectedMonth('');
    }
  }, [selectedYearMonth]);

  const { data, isFetching, isError, status, refetch, error } =
    useGetConsumerInfoListQuery(
      {
        site_id: site,
        year: SelectedYear,
        month: SelectedMonth,
      },
      { skip: !SelectedYear || !SelectedMonth }
    );

  useEffect(() => {
    if (status === 'fulfilled') {
      let statusCode = data?.response_code;
      if (statusCode === 200) {
        setResponse(data?.data);
      }
    } else if (isError) {
      setErrorMessage('something went wrong please retry!');
      if (error?.status === 400) {
        toast(error?.data?.detail, {
          hideProgressBar: true,
          type: 'error',
        });
      } else {
        toast(
          error?.data?.error_message || 'something went wrong please retry!',
          {
            hideProgressBar: true,
            type: 'error',
          }
        );
      }
    }
  }, [data, status, isError, error]);

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const handleRowClick = (data) => {
    Cookies.set('live_status', data.live_status);
    Cookies.set('wallet_balance', data.wallet_balance);
    Cookies.set('connection_type', data.connection_type);
    navigate(`/realestate/gp-admin/mdms/${site}/${data.sc_no}`);
    dispatch(
      updateMDMSHierarchyProgress({
        ...hierarchy,
        user_name: data?.user_name,
        connection_type: data?.connection_type,
        meter_serial_number: data?.meter_serial,
        mdms_state: 'user_profile',
        meter_address: data?.meter_address,
      })
    );
  };

  const tblColumn = () => {
    const column = [];

    for (const i in response?.[0]) {
      const col_config = {};

      if (i !== 'grid_id' && i !== 'live_status') {
        col_config.name = `${i.charAt(0).toUpperCase()}${i.slice(1)}`.replace(
          '_',
          ' '
        );
        col_config.serch = i;
        col_config.sortable = true;
        col_config.selector = (row) => row[i];

        if ((i === 'LDP') | (i === 'consumer_name') | (i === 'consumer_id')) {
          col_config.width = '170px';
        }
        if (i === 'wallet_balance') {
          col_config.cell = (row) => {
            return (
              <div className="d-flex">
                {row[i] === 0 ? (
                  <span>0</span>
                ) : row[i] ? (
                  <span>{row[i]}</span>
                ) : (
                  <span>-</span>
                )}
              </div>
            );
          };
        } else {
          col_config.cell = (row) => {
            return (
              <div className="d-flex">
                <span
                  className="d-block font-weight-bold cursor-pointer"
                  onClick={() => handleRowClick(row, 'pss')}
                  title={
                    row[i]
                      ? row[i] !== ''
                        ? row[i].toString().length > 18
                          ? row[i]
                          : ''
                        : '-'
                      : '-'
                  }
                >
                  {row[i] && row[i] !== ''
                    ? row[i].toString().substring(0, 18)
                    : '-'}
                  {row[i] && row[i] !== ''
                    ? row[i].toString().length > 18
                      ? '...'
                      : ''
                    : '-'}
                </span>
              </div>
            );
          };
        }

        column.push(col_config);
      }
    }

    // Alert Light for Meter LDP
    column.push({
      name: 'Live Status',
      width: '120px',
      cell: (row) => {
        if (row.live_status === 2) {
          return (
            <>
              <Badge color="success" className="mx_7" id="success">
                {/* {row.LDP} */}{' '}
              </Badge>
              <Tooltip
                placement="top"
                isOpen={successTooltip}
                target="success"
                toggle={() => setSuccessTooltip(!successTooltip)}
              >
                LDP within 3 hours
              </Tooltip>
            </>
          );
        } else if (row.live_status === 1) {
          return (
            <>
              <Badge color="warning" className="mx_7" id="processing">
                {/* {row.LDP} */}{' '}
              </Badge>
              <Tooltip
                placement="top"
                isOpen={processingTooltip}
                target="processing"
                toggle={() => setProcessingTooltip(!processingTooltip)}
              >
                LDP within 3 - 6 hours
              </Tooltip>
            </>
          );
        } else if (row.live_status === 0) {
          return (
            <>
              <Badge color="danger" className="mx_7" id="rejected">
                {/* {row.LDP} */}{' '}
              </Badge>
              <Tooltip
                placement="top"
                isOpen={rejectedTooltip}
                target="rejected"
                toggle={() => setRejectedTooltip(!rejectedTooltip)}
              >
                LDP within greater then 6 hours
              </Tooltip>
            </>
          );
        }
      },
    });

    column.unshift({
      name: 'Sr',
      width: '90px',
      cell: (row, i) => {
        return (
          <div className="d-flex  justify-content-center">
            {i + 1 + pageSize * (currentPage - 1)}
          </div>
        );
      },
    });

    return column;
  };

  const retryAgain = () => {
    refetch();
  };

  return (
    <div className="h-100 w-100">
      <span onClick={onBackButtonClickHandler} className="cursor-pointer">
        <ChevronLeft className="mb_3 anim_left" /> Back to DTR List
      </span>
      <Row className="match-height">
        <Col md="12">
          {' '}
          {!isFetching && !isError ? (
            <div className="table-wrapper">
              <DataTableV1
                columns={tblColumn()}
                data={response}
                rowCount={pageSize}
                setRowCount={setRowCount}
                currentPage={currentPage}
                ispagination
                showRefreshButton={true}
                refreshFn={refetch}
                onPageChange={onPageChange}
                pointerOnHover={true}
                totalRowsCount={response?.length}
                onRowClicked={handleRowClick}
                tableName={`User Level (${siteName})`}
              />
            </div>
          ) : (
            <div className="h-100 w-100">
              {isError ? (
                <CardInfo
                  props={{
                    message: { errorMessage },
                    retryFun: { retryAgain },
                    retry: { isFetching },
                  }}
                />
              ) : (
                <>{isFetching && <Loader hight="min-height-475" />}</>
              )}
            </div>
          )}
        </Col>

        <Col xs="12">
          <Row className="match-height">
            <OperationalInformationWrapper
              // height="height-367"
              hierarchy={'user'}
              site={site}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AllUsers;
