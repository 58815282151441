import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_ANALYTICS;

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getAnalyticsReportPostRequest: builder.mutation({
      query: (data) => ({
        url: `${url}/get-recharge-data`,
        method: 'POST',
        body: data,
      }),
    }),
    getDgRuntimeReportPostRequest: builder.mutation({
      query: (data) => ({
        url: `${url}/get-dg-runtime`,
        method: 'POST',
        body: data,
      }),
    }),
    getMeterExchangeReportPostRequest: builder.mutation({
      query: (data) => ({
        url: `${url}/get-meter-change`,
        method: 'POST',
        body: data,
      }),
    }),
    getBillReportPostRequest: builder.mutation({
      query: (data) => ({
        url: `${url}/get-bill-report`,
        method: 'POST',
        body: data,
      }),
    }),
    getDailyConsumptionPostRequest: builder.mutation({
      query: (data) => ({
        url: `${url}/get-daily-consumption`,
        method: 'POST',
        body: data,
      }),
    }),
    getFrociReportPostRequest: builder.mutation({
      query: (data) => ({
        url: `${url}/get-froci-report`,
        method: 'POST',
        body: data,
      }),
    }),
    getVariableCamHistory: builder.mutation({
      query: (data) => ({
        url: `${url}/get-cam-history`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
export const {
  useGetAnalyticsReportPostRequestMutation,
  useGetDgRuntimeReportPostRequestMutation,
  useGetMeterExchangeReportPostRequestMutation,
  useGetBillReportPostRequestMutation,
  useGetDailyConsumptionPostRequestMutation,
  useGetFrociReportPostRequestMutation,
  useGetVariableCamHistoryMutation,
} = analyticsApi;
