import { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import { ChevronLeft } from 'react-feather';
import CommandInfoTableWrapper from './wrapper/commandInfoTableWrapper';
import { useParams, useNavigate } from 'react-router-dom';
import UserDetailWrapper from './wrapper/userDetailWrapper';
import GeneratedBillsWrapper from './wrapper/generatedBillsWrapper';
import TotalRechargesWrapper from './wrapper/totalRechargesWrapper';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetMDMSMeterConnectionStatusQuery } from '../../../../../../../api/mdms/userConsumptionSlice';
import Cookies from 'js-cookie';

const MdmsUserConsumptionModule = (props) => {
  const { ide, site } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // data from url state
  const liveStatus = Cookies.get('live_status');
  const walletBalance = Cookies.get('wallet_balance');
  const connectionType = Cookies.get('connection_type');
  const project =
    location.pathname.split('/')[2] === 'sbpdcl'
      ? 'ipcl'
      : location.pathname.split('/')[2];

  const [meterConnectionStatus, setMeterConnectionStatus] = useState('');

  const hierarchy = useSelector((state) => state.MDMSHierarchyProgress.data);

  const params = {
    project: project,
    sc_no: hierarchy.user_name,
    meter_address: hierarchy.meter_address,
    grid_id: hierarchy.grid_id,
  };
  const { data, isFetching, isError, status } =
    useGetMDMSMeterConnectionStatusQuery(params);

  useEffect(() => {
    if (status === 'fulfilled') {
      let statusCode = data.responseCode;
      if (statusCode === 200) {
        setMeterConnectionStatus(data.data.result.stat.meterConnectionStatus);
      }
    }
  }, [data]);

  const onBackButtonClickHandler = () => {
    navigate(`/realestate/gp-admin/mdms/${site}`);
  };

  if (connectionType === 'postpaid') {
    return (
      <div id="dashboard-ecommerce">
        <Row className="mb-1">
          <Col>
            <span onClick={onBackButtonClickHandler} className="cursor-pointer">
              <ChevronLeft className="mb_3 anim_left" /> Back to User List
            </span>
          </Col>
          <Col className="text-center">
            <span>
              <span
                style={{ position: 'relative', fontSize: '16px', top: '-3px' }}
              >
                Meter status :{' '}
                {liveStatus ? (
                  liveStatus === 2 ? (
                    <strong>Connected</strong>
                  ) : (
                    <strong>Disconnected</strong>
                  )
                ) : (
                  ' '
                )}
              </span>
              {liveStatus ? (
                liveStatus === 2 ? (
                  <div className="con_stat bg-success mt_5"></div>
                ) : liveStatus === 1 ? (
                  <div className="con_stat bg-warning mt_5"></div>
                ) : (
                  <div className="con_stat bg-danger mt_5"></div>
                )
              ) : (
                <Spinner size="sm" className="mb_4" />
              )}
            </span>
          </Col>
        </Row>

        {/* New UI */}

        <Row className="match-height ">
          <Col lg="6" md="6" sm="12">
            <UserDetailWrapper hierarchy={'userProfile'} height="248px" />
          </Col>
          <Col lg="6" md="6" sm="12">
            <GeneratedBillsWrapper
              className={
                'd-flex align-items-center justify-content-center gap-1 flex-column'
              }
              dvClas="py_10"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <CommandInfoTableWrapper
              HierarchyProgress={props.HierarchyProgress}
              // changeState={props.updateMdmsState}
              tableName={'Command info'}
              hierarchy={'user'}
              txtLen={20}
              length={'12'}
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div id="dashboard-ecommerce">
        <Row className="mb-1">
          <Col>
            <span onClick={onBackButtonClickHandler} className="cursor-pointer">
              <ChevronLeft className="mb_3 anim_left" /> Back to User
            </span>
          </Col>
          <Col className="text-center">
            <span>
              <span
                style={{ position: 'relative', fontSize: '16px', top: '-3px' }}
              >
                Meter status :{' '}
                {liveStatus ? (
                  liveStatus === 2 ? (
                    <strong>Connected</strong>
                  ) : (
                    <strong>Disconnected</strong>
                  )
                ) : (
                  ' '
                )}
              </span>
              {liveStatus ? (
                liveStatus === 2 ? (
                  <div className="con_stat bg-success mt_5"></div>
                ) : liveStatus === 1 ? (
                  <div className="con_stat bg-warning mt_5"></div>
                ) : (
                  <div className="con_stat bg-danger mt_5"></div>
                )
              ) : (
                <Spinner size="sm" className="mb_4" />
              )}
            </span>
          </Col>
        </Row>

        {/* New UI */}
        <Row className="match-height px-1">
          <Col lg="4" md="6" sm="12">
            <UserDetailWrapper hierarchy={'userProfile'} height="height-280" />
          </Col>
          <Col lg="4" md="6" sm="12">
            <TotalRechargesWrapper
              scNo={ide}
              walletBalance={walletBalance}
              hierarchy={'userProfile'}
            />
          </Col>
          <Col lg="4" md="6" sm="12">
            <GeneratedBillsWrapper />
          </Col>
        </Row>

        <Row>
          <Col>
            <CommandInfoTableWrapper
              HierarchyProgress={props.HierarchyProgress}
              changeState={props.updateMdmsState}
              tableName={'Command info'}
              hierarchy={'user'}
              txtLen={12}
              length={'12'}
            />
          </Col>
        </Row>
      </div>
    );
  }
};

export default MdmsUserConsumptionModule;
