import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_BULK_COMMAND;

export const bulkCommandApi = createApi({
  reducerPath: 'bulkCommandApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getBulkExecutedTable: builder.query({
      query: (params) => ({
        url: `${url}/bulk-executed-table`,
        params: params,
      }),
      providesTags: ['BulkExecutedTable'],
    }),
    getScNoStatus: builder.query({
      query: ({ id, page, pages }) => ({
        url: `${url}/status-update?id=${id}&page_no=${page}&page_row_limit=${pages}`,
      }),
    }),
    getScNo: builder.mutation({
      query: (data) => ({
        url: `${url}/sc-no`,
        method: 'POST',
        body: data,
      }),
    }),
    getCommandList: builder.query({
      query: () => ({
        url: `${url}/command-list`,
      }),
    }),
    postCommandHistoryTable: builder.mutation({
      query: (data) => ({
        url: `${url}/command-history-table`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BulkExecutedTable'],
    }),
  }),
});
export const {
  useLazyGetBulkExecutedTableQuery,
  useLazyGetScNoStatusQuery,
  useGetCommandListQuery,
  useGetScNoMutation,
  usePostCommandHistoryTableMutation,
} = bulkCommandApi;
