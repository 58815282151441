import { loginApi } from '../api/loginSlice';
import { forgotPasswordApi } from '../api/forgot-passwordSlice';
import { commandHistoryApi } from '../api/hes/command-historySlice';
import { dropdownsApi } from '../api/hes/drop-downSlice';
import logoutApi from '../api/logoutSlice';
import { pushDataApi } from '../api/hes/push-dataSlice';
import { meterConfigurationApi } from '../api/hes/meter-configurationSlice';
import { energyConsumptionApi } from '../api/mdms/energy-consumptionSlice';
import { operationalStatsApi } from '../api/mdms/operational-statisticsSlice';
import { loadsApi } from '../api/mdms/loadSlice';
import { userConsumptionApi } from '../api/mdms/userConsumptionSlice';
import { slaReportsApi } from '../api/sla-reports';
import { bulkCommandApi } from '../api/coliving/bulk-commandSlice';
import { selectSiteApi } from '../api/coliving/selectSiteNameSlice';
import { issueCategoryCreationApi } from '../api/coliving/issue-category-creationSlice';
import { HealthPortalApi } from '../api/coliving/health-portalSlice';
import { satApi } from '../api/sat';
import { userAccessPanelApi } from '../api/user-access-panel';
import { issueManagementApi } from '../api/coliving/issue-managementSlice';
import { billAnalysisApi } from '../api/coliving/bill-analysisSlice';
import { accessControlApi } from '../api/coliving/access-controlSlice';
import { rentDeductionApi } from '../api/coliving/rentDeductionSlice';
import { configurationApi } from '../api/coliving/configurationSlice';
import { meterConfigurationColivingApi } from '../api/coliving/meterConfigurationColivingSlice';
import { reportDetailApi } from '../api/coliving/report-detailSlice';
import { analyticsApi } from '../api/coliving/analyticsSlice';
import { sourceStatusApi } from '../api/realEstate/sourceStatusSlice';
import { dashboardApi } from '../api/realEstate/dashboardSlice';
import { gisApi } from '../api/gis/gis-slice';
import { mdmsApi } from '../api/coliving/mdmsSlice';
import { surveyApi } from '../api/realEstate/surveySlice';
import { usersAccessPanelApi } from '../api/users-access-panel';

const middleware = [
  loginApi.middleware,
  forgotPasswordApi.middleware,
  commandHistoryApi.middleware,
  dropdownsApi.middleware,
  pushDataApi.middleware,
  meterConfigurationApi.middleware,
  logoutApi.middleware,
  energyConsumptionApi.middleware,
  operationalStatsApi.middleware,
  loadsApi.middleware,
  userConsumptionApi.middleware,
  slaReportsApi.middleware,
  satApi.middleware,
  userAccessPanelApi.middleware,
  usersAccessPanelApi.middleware,
  //coliving
  bulkCommandApi.middleware,
  selectSiteApi.middleware,
  issueCategoryCreationApi.middleware,
  HealthPortalApi.middleware,
  issueManagementApi.middleware,
  billAnalysisApi.middleware,
  accessControlApi.middleware,
  rentDeductionApi.middleware,
  configurationApi.middleware,
  meterConfigurationColivingApi.middleware,
  reportDetailApi.middleware,
  analyticsApi.middleware,
  dashboardApi.middleware,
  //realestate
  sourceStatusApi.middleware,
  gisApi.middleware,
  mdmsApi.middleware,
  surveyApi.middleware,
];

export default middleware;
