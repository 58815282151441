import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

// const url = process.env.REACT_APP_MDMS;
const url = 'http://192.168.1.38:8012/mdms';

export const mdmsApi = createApi({
  reducerPath: 'mdmsApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    //site level
    getSiteInfoList: builder.query({
      query: (params) => ({
        url: `${url}/get_site_info`,
        params: params,
      }),
    }),
    getSiteOperationalStatistics: builder.query({
      query: (params) => ({
        url: `${url}/get_site_operational_statistics`,
        params: params,
      }),
    }),
    // user level
    getConsumerInfoList: builder.query({
      query: (params) => ({
        url: `${url}/consumer_level_detail`,
        params: params,
      }),
    }),
    getConsumerOperationalStatistics: builder.query({
      query: (params) => ({
        url: `${url}/consumer_level_statistics_detail`,
        params: params,
      }),
    }),
    // user detail level
    getConsumerRechargeData: builder.query({
      query: (params) => ({
        url: `${url}/consumer_recharge`,
        params: params,
      }),
    }),
    getConsumerRechargeHistoryData: builder.query({
      query: (params) => ({
        url: `${url}/consumer_recharge_history`,
        params: params,
      }),
    }),
    getBillingHistoryData: builder.query({
      query: (params) => ({
        url: `${url}/consumer_billing_history`,
        params: params,
      }),
    }),
  }),
});
export const {
  useGetSiteInfoListQuery,
  useGetSiteOperationalStatisticsQuery,
  useGetConsumerInfoListQuery,
  useGetConsumerOperationalStatisticsQuery,
  useGetConsumerRechargeDataQuery,
  useGetBillingHistoryDataQuery,
  useLazyGetConsumerRechargeHistoryDataQuery,
} = mdmsApi;
