import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_DASHBOARD;

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getUpperDashboard: builder.query({
      query: ({ site_id }) => ({
        url: `${url}/upperdashboard?site_id=${site_id}`,
      }),
    }),
    postMeterStatus: builder.mutation({
      query: (data) => ({
        url: `${url}/detail-meter-status`,
        method: 'POST',
        body: data,
      }),
    }),
    postRemaningCredit: builder.mutation({
      query: (data) => ({
        url: `${url}/detail-remaining-credits`,
        method: 'POST',
        body: data,
      }),
    }),
    postRecharge: builder.mutation({
      query: (data) => ({
        url: `${url}/recharges`,
        method: 'POST',
        body: data,
      }),
    }),
    postConsumerEnergyConsumption: builder.mutation({
      query: (data) => ({
        url: `${url}/consumer-ebdg-energy-consumption`,
        method: 'POST',
        body: data,
      }),
    }),
    postMainEnergyConsumption: builder.mutation({
      query: (data) => ({
        url: `${url}/main-ebdg-energy-consumption`,
        method: 'POST',
        body: data,
      }),
    }),
    postMaintainancePending: builder.mutation({
      query: (data) => ({
        url: `${url}/detail-maintenance-collection`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetUpperDashboardQuery,
  usePostMeterStatusMutation,
  usePostRemaningCreditMutation,
  usePostRechargeMutation,
  usePostConsumerEnergyConsumptionMutation,
  usePostMainEnergyConsumptionMutation,
  usePostMaintainancePendingMutation,
} = dashboardApi;
