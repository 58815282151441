import { combineReducers } from '@reduxjs/toolkit';
import { loginApi } from '../api/loginSlice';
import { forgotPasswordApi } from '../api/forgot-passwordSlice';
import { commandHistoryApi } from '../api/hes/command-historySlice';
import { dropdownsApi } from '../api/hes/drop-downSlice';
import logoutApi from '../api/logoutSlice';
import {
  utilityMDASAssetListReducer,
  utilityMDASDlmsCommandReducer,
} from '../app/redux/commandExecutionSlice';
import { currentSelectedModuleReducer } from './redux/previousSelectedModuleSlice';
import { MDMSHierarchyProgressReducer } from './redux/mdmsHeirarchySlice';
import { pushDataApi } from '../api/hes/push-dataSlice';
import { meterConfigurationApi } from '../api/hes/meter-configurationSlice';
import layoutReducer from './redux/layoutSlice';
import { energyConsumptionApi } from '../api/mdms/energy-consumptionSlice';
import { operationalStatsApi } from '../api/mdms/operational-statisticsSlice';
import { loadsApi } from '../api/mdms/loadSlice';
import { userConsumptionApi } from '../api/mdms/userConsumptionSlice';
import { slaReportsApi } from '../api/sla-reports';
import { satApi } from '../api/sat';
import { userAccessPanelApi } from '../api/user-access-panel';
import { usersAccessPanelApi } from '../api/users-access-panel';
import { bulkCommandApi } from '../api/coliving/bulk-commandSlice';
import { selectSiteApi } from '../api/coliving/selectSiteNameSlice';
import { issueCategoryCreationApi } from '../api/coliving/issue-category-creationSlice';
import { HealthPortalApi } from '../api/coliving/health-portalSlice';
import { issueManagementApi } from '../api/coliving/issue-managementSlice';
import { billAnalysisApi } from '../api/coliving/bill-analysisSlice';
import { accessControlApi } from '../api/coliving/access-controlSlice';
import { rentDeductionApi } from '../api/coliving/rentDeductionSlice';
import { configurationApi } from '../api/coliving/configurationSlice';
import { meterConfigurationColivingApi } from '../api/coliving/meterConfigurationColivingSlice';
import { reportDetailApi } from '../api/coliving/report-detailSlice';
import { analyticsApi } from '../api/coliving/analyticsSlice';
import { sourceStatusApi } from '../api/realEstate/sourceStatusSlice';
import { dashboardApi } from '../api/realEstate/dashboardSlice';
import mainConsumerEnergyReducer from '../api/realEstate/mainConsumerEnergySlice';
import routingReducer from '../api/realEstate/routingSlice';
import calenderReducer from '../api/realEstate/calenderSlice';
import { gisApi } from '../api/gis/gis-slice';
import { mdmsApi } from '../api/coliving/mdmsSlice';
import { surveyApi } from '../api/realEstate/surveySlice';
import utilityGISMaps from './redux/gisSlice';

const rootReducer = combineReducers({
  routing: routingReducer,
  calender: calenderReducer,
  mainConsumerEnergy: mainConsumerEnergyReducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
  [commandHistoryApi.reducerPath]: commandHistoryApi.reducer,
  [pushDataApi.reducerPath]: pushDataApi.reducer,
  [logoutApi.reducerPath]: logoutApi.reducer,
  [meterConfigurationApi.reducerPath]: meterConfigurationApi.reducer,
  [energyConsumptionApi.reducerPath]: energyConsumptionApi.reducer,
  [operationalStatsApi.reducerPath]: operationalStatsApi.reducer,
  [loadsApi.reducerPath]: loadsApi.reducer,
  [userConsumptionApi.reducerPath]: userConsumptionApi.reducer,
  [slaReportsApi.reducerPath]: slaReportsApi.reducer,
  [userAccessPanelApi.reducerPath]: userAccessPanelApi.reducer,
  [usersAccessPanelApi.reducerPath]: usersAccessPanelApi.reducer,
  //coliving
  [bulkCommandApi.reducerPath]: bulkCommandApi.reducer,
  [selectSiteApi.reducerPath]: selectSiteApi.reducer,
  [reportDetailApi.reducerPath]: reportDetailApi.reducer,
  [issueCategoryCreationApi.reducerPath]: issueCategoryCreationApi.reducer,
  [HealthPortalApi.reducerPath]: HealthPortalApi.reducer,
  [issueManagementApi.reducerPath]: issueManagementApi.reducer,
  [billAnalysisApi.reducerPath]: billAnalysisApi.reducer,
  [accessControlApi.reducerPath]: accessControlApi.reducer,
  [rentDeductionApi.reducerPath]: rentDeductionApi.reducer,
  [configurationApi.reducerPath]: configurationApi.reducer,
  [meterConfigurationColivingApi.reducerPath]:
    meterConfigurationColivingApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  //realestate
  [sourceStatusApi.reducerPath]: sourceStatusApi.reducer,
  [gisApi.reducerPath]: gisApi.reducer,
  [mdmsApi.reducerPath]: mdmsApi.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  utilityGISMaps: utilityGISMaps,
  [dropdownsApi.reducerPath]: dropdownsApi.reducer,
  [satApi.reducerPath]: satApi.reducer,
  layout: layoutReducer,
  utilityMDASAssetList: utilityMDASAssetListReducer,
  utilityMDASDlmsCommand: utilityMDASDlmsCommandReducer,
  currentSelectedModule: currentSelectedModuleReducer,
  MDMSHierarchyProgress: MDMSHierarchyProgressReducer,
});

export default rootReducer;
