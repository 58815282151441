import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: [],
  total: 0,
  response2: [],
  total2: 0,
};

const mainConsumerEnergySlice = createSlice({
  name: 'mainConsumerEnergy',
  initialState,
  reducers: {
    setMainEnergyData: (state, action) => {
      state.response = action.payload.response;
      state.total = action.payload.total;
    },
    setConsumerEnergyData: (state, action) => {
      state.response2 = action.payload.response;
      state.total2 = action.payload.total;
    },
  },
});
export const { setConsumerEnergyData, setMainEnergyData } =
  mainConsumerEnergySlice.actions;

export default mainConsumerEnergySlice.reducer;
