import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import CardinlineMultiData from '../../../../../../../components/ui-elements/statistics/cardinlineMultiData';
import Loader from '../../../../../../../components/loader/loader';
import CardInfo from '../../../../../../../components/ui-elements/cards/cardInfo';
import { useGetConsumerOperationalStatisticsQuery } from '../../../../../../../api/coliving/mdmsSlice';

const OperationalInformationWrapper = (props) => {
  const [statData, setStatData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // date and time coming from redux
  const selectedYearMonth = useSelector((state) => state.calender.data);

  const [SelectedYear, setSelectedYear] = useState('');
  const [SelectedMonth, setSelectedMonth] = useState('');

  useEffect(() => {
    // Ensure it's a valid string before splitting
    if (
      typeof selectedYearMonth === 'string' &&
      selectedYearMonth.includes('-')
    ) {
      const [year, month] = selectedYearMonth.split('-');
      setSelectedYear(year);
      setSelectedMonth(month);
    } else {
      // Handle cases where selectedYearMonth is not in the expected format
      console.log('Invalid date format:', selectedYearMonth);
      setSelectedYear('');
      setSelectedMonth('');
    }
  }, [selectedYearMonth]);

  const params = {
    year: SelectedYear,
    month: SelectedMonth,
    site_id: props.site,
  };

  const {
    isFetching: isLoading,
    isError,
    data: apiData,
    status,
    refetch,
  } = useGetConsumerOperationalStatisticsQuery(params, {
    skip: !SelectedMonth || !SelectedYear,
  });

  // Effect to handle data fetching and state update
  useEffect(() => {
    if (status === 'fulfilled') {
      const statusCode = apiData?.response_code;
      if (statusCode === 200) {
        const result = apiData?.data?.[0];
        // Map the result data to fit the previous structure
        const formattedData = [
          { title: 'Total Meters', value: result.Total_Meters },
          {
            title: 'Recharge Amount',
            value: `₹ ${Number(result.Recharge_Amount || 0).toFixed(2)}`,
          },
          { title: 'Count Recharges', value: result.Recharge_Count },
          { title: 'Prepaid Meters', value: result.Prepaid },
          { title: 'Postpaid Meters', value: result.Postpaid },
        ];
        setStatData(formattedData);
      }
    } else if (isError) {
      setErrorMessage('Something went wrong...');
    }
  }, [apiData, status, isError]);

  // Retry function
  const retryAgain = () => {
    if (isError) {
      refetch();
    }
  };

  return (
    <Col lg="12" id="getHeight">
      {isError ? (
        <CardInfo
          props={{
            message: errorMessage,
            retryFun: { retryAgain },
            retry: { retry: isLoading },
          }}
        />
      ) : (
        <>
          {isLoading ? (
            <Loader hight={props.height ? props.height : 'min-height-176'} />
          ) : (
            <CardinlineMultiData
              cols={props.cols ? props.cols : { xl: '3', md: '4', sm: '6' }}
              data={statData}
            />
          )}
        </>
      )}
    </Col>
  );
};

export default OperationalInformationWrapper;
