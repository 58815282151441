import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = 'https://api.staging.private.polarisgrids.com/survey';
// const url = 'http://192.168.100.125:8013';

export const surveyApi = createApi({
  reducerPath: 'surveyApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    // Feeder/Project Level
    getProjectSurvey: builder.query({
      query: () => ({
        url: `${url}/get_survey_project_info`,
      }),
      providesTags: ['ProjectSurvey'],
    }),
    getVerticalList: builder.query({
      query: () => ({
        url: `${url}/get_vertical_info`,
      }),
    }),
    postProjectSurvey: builder.mutation({
      query: (data) => ({
        url: `${url}/project_survey_configured_data`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ProjectSurvey'],
    }),
    putProjectSurvey: builder.mutation({
      query: (data) => ({
        url: `${url}/project_survey_modified_data`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ProjectSurvey'],
    }),
    // Site Level
    getSiteSurvey: builder.query({
      query: () => ({
        url: `${url}/get_survey_site_info`,
      }),
      providesTags: ['SiteSurvay'],
    }),
    getProjectList: builder.query({
      query: () => ({
        url: `${url}/get_project_list`,
      }),
    }),
    postSiteSurvey: builder.mutation({
      query: (data) => ({
        url: `${url}/site_configured_data`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['SiteSurvay'],
    }),
    SiteReconfigure: builder.mutation({
      query: (data) => ({
        url: `${url}/site_reconfigured`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['SiteSurvay'],
    }),
    putSiteSurvey: builder.mutation({
      query: (data) => ({
        url: `${url}/site_modification`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['SiteSurvay'],
    }),
    // house Level
    getHouseSurvey: builder.query({
      query: (params) => ({
        url: `${url}/survey_house_hold_detail`,
        params: params,
      }),
      providesTags: ['HouseSurvey'],
    }),
    getProjectType: builder.query({
      query: (params) => ({
        url: `${url}/get_project_type`,
        params: params,
      }),
    }),
    meterReplacement: builder.mutation({
      query: (data) => ({
        url: `${url}/meter_replacement`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['HouseSurvey'],
    }),
    getSiteList: builder.query({
      query: () => ({
        url: `${url}/get_site_list`,
      }),
      invalidatesTags: ['SiteSurvay'],
    }),
    getMeterImage: builder.query({
      query: (params) => ({
        url: `${url}/get_image_detail`,
        params: params,
      }),
      providesTags: ['MeterImage'],
    }),
    deleteMeterImage: builder.mutation({
      query: (data) => ({
        url: `${url}/delete_image`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['MeterImage', 'HouseSurvey'],
    }),
    postHouseSurvey: builder.mutation({
      query: (data) => {
        // Create FormData here
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (value instanceof FileList || Array.isArray(value)) {
            // Handle files or arrays
            value.forEach((file) => formData.append(key, file));
          } else {
            formData.append(key, value);
          }
        });

        return {
          url: `${url}/house_configured_data`,
          method: 'POST',
          body: formData,
          headers: {
            // Let the browser set Content-Type for FormData
          },
        };
      },
      invalidatesTags: ['HouseSurvey'],
    }),
    putHouseSurvey: builder.mutation({
      query: (data) => {
        // Create FormData here
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (value instanceof FileList || Array.isArray(value)) {
            // Handle files or arrays
            value.forEach((file) => formData.append(key, file));
          } else {
            formData.append(key, value);
          }
        });

        return {
          url: `${url}/house_modification_data`,
          method: 'PUT',
          body: formData,
          headers: {
            // Let the browser set Content-Type for FormData
          },
        };
      },
      invalidatesTags: ['HouseSurvey', 'MeterImage'],
    }),
    // putHouseSurvey: builder.mutation({
    //   query: (data) => ({
    //     url: `${url}/house_modification_data`,
    //     method: 'PUT',
    //     body: data,
    //   }),
    //   invalidatesTags: ['HouseSurvey', 'MeterImage'],
    // }),
  }),
});

export const {
  // Feeder/Project Level
  useGetProjectSurveyQuery,
  usePostProjectSurveyMutation,
  usePutProjectSurveyMutation,
  useGetVerticalListQuery,
  // Site Level
  useGetSiteSurveyQuery,
  useGetProjectListQuery,
  usePostSiteSurveyMutation,
  useSiteReconfigureMutation,
  usePutSiteSurveyMutation,
  // House Level
  useGetProjectTypeQuery,
  useGetHouseSurveyQuery,
  useMeterReplacementMutation,
  useGetSiteListQuery,
  useGetMeterImageQuery,
  useDeleteMeterImageMutation,
  usePostHouseSurveyMutation,
  usePutHouseSurveyMutation,
} = surveyApi;
