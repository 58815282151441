import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_BILL_ANALYSIS;

export const billAnalysisApi = createApi({
  reducerPath: 'billAnalysisApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getBillAnalysisDataList: builder.query({
      query: (params) => ({
        url: `${url}/analyze`,
        params: params,
      }),
      providesTags: ['BillAnalysisDataList'],
    }),
    getBillAnalysisDetail: builder.query({
      query: (id) => ({
        url: `${url}/analyze/${id}`,
      }),
    }),
    uploadAnalysisReport: builder.mutation({
      query: ({ id, params }) => ({
        url: `${url}/analyze/${id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['BillAnalysisDataList'],
    }),
  }),
});
export const {
  useGetBillAnalysisDataListQuery,
  useGetBillAnalysisDetailQuery,
  useUploadAnalysisReportMutation,
} = billAnalysisApi;
