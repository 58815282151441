import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

// const url = process.env.REACT_APP_COLIVING_ISSUES;
const url = 'http://192.168.101.7:8105/ims';

export const issueCategoryCreationApi = createApi({
  reducerPath: 'issueCategoryCreationApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getColivingIssueCategories: builder.query({
      query: (params) => ({
        url: `${url}/issue-categories`,
        params: params,
      }),
      providesTags: ['ColivingIssueCategories'],
    }),
    putColivingIssueCategories: builder.mutation({
      query: (data) => ({
        url: `${url}/issue-categories`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ColivingIssueCategories'],
    }),
    deleteColivingIssueCategories: builder.mutation({
      query: (id) => ({
        url: `${url}/issue-categories?id=${id}`,
        method: 'DELETE',
      }),
    }),
    postColivingIssueCategories: builder.mutation({
      query: (params) => ({
        url: `${url}/issue-categories`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['ColivingIssueCategories'],
    }),
  }),
});
export const {
  useGetColivingIssueCategoriesQuery,
  usePutColivingIssueCategoriesMutation,
  useDeleteColivingIssueCategoriesMutation,
  usePostColivingIssueCategoriesMutation,
} = issueCategoryCreationApi;
