import {
  LazyColivingComponents,
  LazyRealEstateComponents,
  LazyUtilityComponents,
} from './lazyComponents';

export const routes = [
  {
    id: 1,
    title: 'LPDD',
    icon: 'Award',
    key: 'lpdd',
    modules: [
      {
        id: 1,
        title: 'HES',
        path: '/utility/lpdd/hes',
        icon: 'Circle',
        key: 'lpdd-hes',
        component: LazyUtilityComponents.Hes,
      },
      {
        id: 2,
        title: 'MDMS',
        path: '/utility/lpdd/mdms',
        icon: 'Circle',
        key: 'lpdd-mdms',
        component: LazyUtilityComponents.Mdms,
      },
      {
        id: 3,
        title: 'SLA REPORTS',
        path: '/utility/lpdd/sla-reports',
        icon: 'Circle',
        key: 'lpdd-sla',
        component: LazyUtilityComponents.SlaReports,
      },
      {
        id: 4,
        title: 'SAT',
        path: '/utility/lpdd/sat',
        icon: 'Circle',
        key: 'lpdd-sat',
        component: LazyUtilityComponents.Sat,
      },
      {
        id: 5,
        title: 'GIS',
        path: '/utility/lpdd/gis',
        icon: 'Circle',
        key: 'lpdd-gis',
        component: LazyRealEstateComponents.Gis,
      },
    ],
  },
  {
    id: 2,
    title: 'SBPDCL',
    icon: 'Award',
    key: 'sbpdcl',
    modules: [
      {
        id: 1,
        title: 'HES',
        path: '/utility/sbpdcl/hes',
        icon: 'Circle',
        key: 'sbpdcl-hes',
        component: LazyUtilityComponents.Hes,
      },
      {
        id: 2,
        title: 'MDMS',
        path: '/utility/sbpdcl/mdms',
        icon: 'Circle',
        key: 'sbpdcl-mdms',
        component: LazyUtilityComponents.Mdms,
      },
      {
        id: 3,
        title: 'SLA REPORTS',
        path: '/utility/sbpdcl/sla-reports',
        icon: 'Circle',
        key: 'sbpdcl-sla',
        component: LazyUtilityComponents.SlaReports,
      },
      {
        id: 4,
        title: 'SAT',
        path: '/utility/sbpdcl/sat',
        icon: 'Circle',
        key: 'sbpdcl-sat',
        component: LazyUtilityComponents.Sat,
      },
      {
        id: 5,
        title: 'GIS',
        path: '/utility/sbpdcl/gis',
        icon: 'Circle',
        key: 'sbpdcl-gis',
        component: LazyRealEstateComponents.Gis,
      },
    ],
  },
  {
    id: 3,
    title: 'Coliving',
    icon: 'Award',
    key: 'coliving',
    Project: [
      {
        id: 1,
        title: 'GP-Admin',
        icon: 'Circle',
        key: 'gp-admin',
        path: 'coliving/gp-admin',
        modules: [
          {
            id: 1,
            title: 'BulkCommand',
            path: `/coliving/gp-admin/bulk-command`,
            icon: 'Circle',
            key: 'coliving-bulk-command',
            component: LazyColivingComponents.BulkCommand,
          },
          {
            id: 2,
            title: 'Health-portal',
            path: '/coliving/gp-admin/health-portal',
            icon: 'Circle',
            key: 'coliving-health-portal',
            component: LazyColivingComponents.HealthPortal,
          },
          {
            id: 3,
            title: 'Bill-analysis',
            path: '/coliving/gp-admin/bill-analysis',
            icon: 'Circle',
            key: 'coliving-bill-analysis',
            component: LazyColivingComponents.Billanalysis,
          },
          {
            id: 4,
            title: 'Rent-collection',
            path: '/coliving/gp-admin/rent-collection',
            icon: 'Circle',
            key: 'coliving-rent-collection',
            component: LazyColivingComponents.RentCollection,
          },
          {
            id: 5,
            title: 'Configuration',
            path: '/coliving/gp-admin/configuration',
            icon: 'Circle',
            key: 'coliving-configuration',
            component: LazyColivingComponents.Configuration,
          },
          {
            id: 6,
            title: 'Analytics',
            path: '/coliving/gp-admin/analytics',
            icon: 'Circle',
            key: 'coliving-analytics',
            component: LazyColivingComponents.Analytics,
          },
          {
            id: 7,
            title: 'Dashboard',
            path: '/coliving/gp-admin/dashboard',
            icon: 'Circle',
            key: 'coliving-dashboard',
            component: LazyColivingComponents.Dashboard,
          },
          {
            id: 8,
            title: 'MDMS',
            path: '/coliving/gp-admin/mdms',
            icon: 'Circle',
            key: 'coliving-mdms',
            component: LazyColivingComponents.Mdms,
          },
          {
            id: 9,
            title: 'Survey',
            path: '/coliving/gp-admin/survey',
            icon: 'Circle',
            key: 'coliving-survey',
            component: LazyColivingComponents.Survey,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'Realestate',
    icon: 'Award',
    key: 'realestate',
    Project: [
      {
        id: 1,
        title: 'GP-Admin',
        icon: 'Circle',
        key: 'gp-admin',
        path: 'realestate/gp-admin',
        modules: [
          {
            id: 1,
            title: 'BulkCommand',
            path: `/realestate/gp-admin/bulk-command`,
            icon: 'Circle',
            key: 'realEstate-bulk-command',
            component: LazyRealEstateComponents.BulkCommand,
          },
          {
            id: 2,
            title: 'Health-portal',
            path: '/realestate/gp-admin/health-portal',
            icon: 'Circle',
            key: 'realEstate-health-portal',
            component: LazyRealEstateComponents.HealthPortal,
          },
          {
            id: 3,
            title: 'Configuration',
            path: '/realestate/gp-admin/configuration',
            icon: 'Circle',
            key: 'realEstate-configuration',
            component: LazyRealEstateComponents.Configuration,
          },
          {
            id: 4,
            title: 'Analytics',
            path: '/realestate/gp-admin/analytics',
            icon: 'Circle',
            key: 'realEstate-analytics',
            component: LazyRealEstateComponents.Analytics,
          },
          {
            id: 5,
            title: 'Dashboard',
            path: '/realestate/gp-admin/dashboard',
            icon: 'Circle',
            key: 'realEstate-dashboard',
            component: LazyRealEstateComponents.Dashboard,
          },
          {
            id: 6,
            title: 'MDMS',
            path: '/realestate/gp-admin/mdms',
            icon: 'Circle',
            key: 'realEstate-mdms',
            component: LazyRealEstateComponents.Mdms,
          },
          {
            id: 7,
            title: 'GIS',
            path: '/realestate/gp-admin/gis',
            icon: 'Circle',
            key: 'realEstate-gis',
            component: LazyRealEstateComponents.Gis,
          },
          {
            id: 8,
            title: 'HES',
            path: '/realestate/gp-admin/hes',
            icon: 'Circle',
            key: 'realEstate-hes',
            component: LazyRealEstateComponents.Hes,
          },
          {
            id: 9,
            title: 'Survey',
            path: '/realestate/gp-admin/survey',
            icon: 'Circle',
            key: 'realEstate-survey',
            component: LazyRealEstateComponents.Survey,
          },
        ],
      },
      {
        id: 2,
        title: 'ROFAnanda',
        icon: 'Circle',
        key: 'rofananda',
        path: 'realestate/rofananda',
        modules: [
          {
            id: 1,
            title: 'Analytics',
            path: '/realestate/rofananda/analytics',
            icon: 'Circle',
            key: 'realEstate-analytics',
            component: LazyRealEstateComponents.Analytics,
          },
          {
            id: 2,
            title: 'Configuration',
            path: '/realestate/rofananda/configuration',
            icon: 'Circle',
            key: 'realEstate-configuration',
            component: LazyRealEstateComponents.Configuration,
          },
          {
            id: 3,
            title: 'BulkCommand',
            path: `/realestate/rofananda/bulk-command`,
            icon: 'Circle',
            key: 'realEstate-bulk-command',
            component: LazyRealEstateComponents.BulkCommand,
          },
          {
            id: 4,
            title: 'Health-portal',
            path: '/realestate/rofananda/health-portal',
            icon: 'Circle',
            key: 'realEstate-health-portal',
            component: LazyRealEstateComponents.HealthPortal,
          },
        ],
      },
      {
        id: 3,
        title: 'Trimurty',
        icon: 'Circle',
        key: 'trimurty',
        path: 'realestate/trimurty',
        modules: [
          {
            id: 1,
            title: 'Analytics',
            path: '/realestate/trimurty/analytics',
            icon: 'Circle',
            key: 'realEstate-analytics',
            component: LazyRealEstateComponents.Analytics,
          },
          {
            id: 2,
            title: 'Configuration',
            path: '/realestate/trimurty/configuration',
            icon: 'Circle',
            key: 'realEstate-configuration',
            component: LazyRealEstateComponents.Configuration,
          },
          {
            id: 3,
            title: 'BulkCommand',
            path: `/realestate/trimurty/bulk-command`,
            icon: 'Circle',
            key: 'realEstate-bulk-command',
            component: LazyRealEstateComponents.BulkCommand,
          },
          {
            id: 4,
            title: 'Health-portal',
            path: '/realestate/trimurty/health-portal',
            icon: 'Circle',
            key: 'realEstate-health-portal',
            component: LazyRealEstateComponents.HealthPortal,
          },
        ],
      },
      {
        id: 3,
        title: 'Parsvnath-prestige-rwa',
        icon: 'Circle',
        key: 'parsvnath-prestige-rwa',
        path: 'realestate/parsvnath-prestige-rwa',
        modules: [
          {
            id: 1,
            title: 'Analytics',
            path: '/realestate/parsvnath-prestige-rwa/analytics',
            icon: 'Circle',
            key: 'realEstate-analytics',
            component: LazyRealEstateComponents.Analytics,
          },
          {
            id: 2,
            title: 'Configuration',
            path: '/realestate/parsvnath-prestige-rwa/configuration',
            icon: 'Circle',
            key: 'realEstate-configuration',
            component: LazyRealEstateComponents.Configuration,
          },
          {
            id: 3,
            title: 'BulkCommand',
            path: `/realestate/parsvnath-prestige-rwa/bulk-command`,
            icon: 'Circle',
            key: 'realEstate-bulk-command',
            component: LazyRealEstateComponents.BulkCommand,
          },
          {
            id: 4,
            title: 'Health-portal',
            path: '/realestate/parsvnath-prestige-rwa/health-portal',
            icon: 'Circle',
            key: 'realEstate-health-portal',
            component: LazyRealEstateComponents.HealthPortal,
          },
        ],
      },
      {
        id: 4,
        title: 'Stowa',
        icon: 'Circle',
        key: 'stowa',
        path: 'realestate/stowa',
        modules: [
          {
            id: 1,
            title: 'Analytics',
            path: '/realestate/stowa/analytics',
            icon: 'Circle',
            key: 'realEstate-analytics',
            component: LazyRealEstateComponents.Analytics,
          },
          {
            id: 2,
            title: 'Configuration',
            path: '/realestate/stowa/configuration',
            icon: 'Circle',
            key: 'realEstate-configuration',
            component: LazyRealEstateComponents.Configuration,
          },
          {
            id: 3,
            title: 'BulkCommand',
            path: `/realestate/stowa/bulk-command`,
            icon: 'Circle',
            key: 'realEstate-bulk-command',
            component: LazyRealEstateComponents.BulkCommand,
          },
          {
            id: 4,
            title: 'Health-portal',
            path: '/realestate/stowa/health-portal',
            icon: 'Circle',
            key: 'realEstate-health-portal',
            component: LazyRealEstateComponents.HealthPortal,
          },
        ],
      },
    ],
  },
];
