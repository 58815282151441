import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_CONSUMER_CONFIG;

export const accessControlApi = createApi({
  reducerPath: 'accessControlApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getPropertyOwnerModuleAccessTable: builder.query({
      query: (params) => ({
        url: `${url}/owner-access-control`,
        params: params,
      }),
      providesTags: ['PropertyOwnerModuleAccessTable'],
    }),
    deletePropertyOwnerModuleAccess: builder.mutation({
      query: (id) => ({
        url: `${url}/owner-access-control/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PropertyOwnerModuleAccessTable'],
    }),
    getSiteList: builder.query({
      query: (params) => ({
        url: `${url}/owner-access-control/get-site-list`,
        params: params,
      }),
    }),
    addPropertyOwnerModuleAccess: builder.mutation({
      query: (params) => ({
        url: `${url}/owner-access-control`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['PropertyOwnerModuleAccessTable'],
    }),
    updatePropertyOwnerModuleAccess: builder.mutation({
      query: (params) => ({
        url: `${url}/owner-access-control/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['PropertyOwnerModuleAccessTable'],
    }),
  }),
});
export const {
  useGetPropertyOwnerModuleAccessTableQuery,
  useDeletePropertyOwnerModuleAccessMutation,
  useGetSiteListQuery,
  useAddPropertyOwnerModuleAccessMutation,
  useUpdatePropertyOwnerModuleAccessMutation,
} = accessControlApi;
